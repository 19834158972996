<template>
  <header id="header">
    <div class="container">
      <img src="../../assets/images/logo.png" width="300" />
      <nav>
        <HeaderCenterNav />
      </nav>
      <HeaderRightNav />
    </div>
  </header>
</template>

<script>
import HeaderCenterNav from "./HeaderCenterNav.vue";
import HeaderRightNav from "./HeaderRightNav.vue";
import { isNullOrUndefinedOrEmpty } from "@/utils/stringHelper.js";
import { getUserInfo } from "@/api/account";
import { mapState, mapMutations } from "vuex";
import { mutationsType } from "@/store/mutations-type.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "CommonHeader",
  components: { HeaderRightNav, HeaderCenterNav },
  data: () => {
    return {
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    if (
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_TOKEN)
      ) &&
      !isNullOrUndefinedOrEmpty(
        localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      ) &&
      isNullOrUndefinedOrEmpty(this.accountInfo.id)
    ) {
      this.refreshUserInfo();
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    ...mapMutations([mutationsType.SET_ACCOUNT_INFO]),
    refreshUserInfo() {
      getUserInfo({
        id: localStorage.getItem(LOCAL_STORAGE_KEY.RPA_USER_ID)
      }).then(response => {
        this[mutationsType.SET_ACCOUNT_INFO](response.data);
      });
    }
  }
};
</script>

<style>
#header {
  z-index: 1;
  height: 60px;
  flex-shrink:0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;  
  box-shadow: 2px 2px 5px #f1f1f1;
}
#header .container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

#header nav {
  flex: 1;
  margin-left: 50px;
}

@media screen and (max-width: 1519px) {
  #header {
    height: 120px;
  }
}
</style>
