<template>
  <div id="layout">
    <CommonHeader />
    <div class="main" v-if="this.$route.path=='/account/loginOut' || this.$route.path=='/diff' || accountInfo.id">
      <router-view></router-view>
    </div>
    <CommonFooter />
  </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader.vue";
import CommonFooter from "@/components/common/CommonFooter.vue";
import { mapState } from "vuex";
export default {
  name: "Layout",
  components: { CommonHeader, CommonFooter },
  computed: {
    ...mapState(["accountInfo"])
  },
};
</script>
<style>
#layout {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}
#layout .main {
  font-size: 14px;
  margin: 0px 0px 20px 0px;
  color: #666;
  line-height: 1.42857;
  padding: 25px;
  min-width: 1280px;
  /*background-color: #fff;*/
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

#layout .el-breadcrumb {
  margin-bottom: 30px;
}
</style>
