<template>
  <ul class="rightNav">
    <li>
      <el-dropdown>
        <el-button size="mini" round>
        <!--span class="el-dropdown-link"-->
          &nbsp;<i class="el-icon-user"></i>&nbsp;
          {{
            accountInfo.name
          }}
          <i class="el-icon-arrow-down el-icon--right"></i>
        <!--/span-->
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item >バージョン{{version}}</el-dropdown-item>
          <!--<el-dropdown-item :command="ROUTER_PATH.ACCOUNT_RESET_PASSWORD"><i class="el-icon-key"></i>密码修改</el-dropdown-item>-->
          <el-dropdown-item @click.native="handleCommand()"
            ><i class="el-icon-switch-button"></i>ログアウト</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>

    </li>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "HeaderRightNav",
  data: () => {
    return {
      ROUTER_PATH: ROUTER_PATH,
        version: process.env.VUE_APP_WORKSTATION_VERSION
    };
  },
  methods: {
    handleCommand() {
      //   console.log(ROUTER_PATH.LOGIN_OUT);
      // localStorage.setItem(LOCAL_STORAGE_KEY.RPA_TOKEN, "");
      // localStorage.setItem(LOCAL_STORAGE_KEY.RPA_USER_ID, "");
      this.$router.push(ROUTER_PATH.LOGIN_OUT);
    }
  },
  computed: {
    ...mapState(["accountInfo"])
  }
};
</script>

<style>
.rightNav {
  display: flex;
  list-style: none;
}
.rightNav svg {
  margin-right: 8px;
}
.rightNav .active span {
  color: #0055f9;
}
.rightNav .active span:hover {
  color: #0055f9;
}
.rightNav li {
  color: #999;
  cursor: pointer;
  font-size: 14px;
  margin-left: 30px;
}
.rightNav li span {
  font-size: 14px;
  color: #999;
}
.rightNav li:hover {
  color: #333;
}
.rightNav li span:hover {
  color: #333;
}
</style>
