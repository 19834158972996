<template>
  <footer id="footer">
    (C) Precious Partners Co., Ltd.
  </footer>
</template>

<script>
export default {
  name: "CommonFooter"
};
</script>
<style>
#footer { 
  width:100%;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background-color: #333a40;
  color: #fff;
  font-size: 12px;
}
</style>
