<template>
  <el-menu
    mode="horizontal"
    active-text-color="#0B7CC4"
    :default-active="paths"
    router="router"
  >
    <el-menu-item :index="ROUTER_PATH.APPLY_CENTER">
      <span slot="title">案件一覧</span>
    </el-menu-item>
    <el-submenu :index="LINK_CENTER" v-if="accountInfo.role <= 3 || accountInfo.showMenuFlg == 1">
      <template slot="title">依頼関連</template>
      <el-menu-item :index="LINK1">
        <el-link href="https://forms.office.com/r/RiqR5HM8Fy" target="_blank" :underline="false">オプション枠取り</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK2">
        <el-link href="https://forms.office.com/r/YWcawt8qBY" target="_blank" :underline="false">オプション紐づけ変更</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK3">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSeBZlTOh-WBo01lUyys1Yz0Cs8cqZJ9SvvklQM-Ay7c5JsN0Q/viewform" target="_blank" :underline="false">キャンセル依頼</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK4">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSdq_kPMn3SfzjUVxAxqMf1pGp00TrU3k-d0_UEm8_jx8jURUg/viewform" target="_blank" :underline="false">実績照会 </el-link>
      </el-menu-item>
      <el-menu-item :index="LINK5">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSfX_g-DHP0SKIy7W8IRXt1-QKcqa1-AI8BZ4-DRnrgjUnqjPQ/viewform" target="_blank" :underline="false">反響事例抽出</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK6">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSeqBmha6lVWIUC1qzhXv49VH5pjJjCJ4QuJQxzD9q09XWNrOQ/viewform" target="_blank" :underline="false">アクセスレポート</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK7">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSd-9ZWVoVlOGIbifBTmPaH4wZcEX4Q8qzbkAJP56AUMvl6JoA/viewform" target="_blank" :underline="false">ナイト審査</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK8">
        <el-link href="https://forms.office.com/r/zGLvk8ZdWx" target="_blank" :underline="false">マルチ有効化</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK9">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLScX06UIngwVFcorHx2VehWIeKm4FeVRz1ngEnhP4RQyHeMlXA/viewform" target="_blank" :underline="false">『得パス』プラン 購入</el-link>
      </el-menu-item>
      <el-menu-item :index="LINK10">
        <el-link href="https://docs.google.com/forms/d/e/1FAIpQLSdLCTkRcaQ-xTycSWfN7SzpHVbQOtwXg4hI-IezCBOIjjI1yw/viewform" target="_blank" :underline="false">【ナイト】店舗情報変更依頼</el-link>
      </el-menu-item>
    </el-submenu>
    <el-menu-item :index="LINK_PARTNER" v-if="accountInfo.role <= 3 || accountInfo.showMenuFlg == 1">
      <el-link href="https://relation.partnersuccess.app/auth/login" target="_blank" :underline="false">パートナーサクセス</el-link>
    </el-menu-item>
    <el-submenu :index="ROUTER_PATH.ADMIN_USER">
      <template slot="title">アカウント</template>
      <el-menu-item :index="ROUTER_PATH.ADMIN_GROUP">グループ</el-menu-item>
      <el-menu-item :index="ROUTER_PATH.ADMIN_USER">ユーザー</el-menu-item>
    </el-submenu>
    <el-menu-item :index="ROUTER_PATH.KBN_MASTER" v-if="accountInfo.role <= 3">
      <span slot="title">マスター管理</span>
    </el-menu-item>
    <el-menu-item :index="ROUTER_PATH.SYS_CONFIG" v-if="accountInfo.role <= 3">
      <span slot="title">システム設定</span>
    </el-menu-item>
    <el-menu-item :index="ROUTER_PATH.ADMIN_RPA" v-if="(accountInfo.id == 1 || accountInfo.id == 42 || accountInfo.id == 43 || accountInfo.id == 47) && accountInfo.role == 1">
      <span slot="title">Rpaサーバー管理</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { mapState } from "vuex";
import { ROUTER_PATH } from "@/constants/common.js";
export default {
  name: "HeaderCenterNav",
  data: () => {
    return {
      ROUTER_PATH: ROUTER_PATH,
      paths: window.location.hash.replace('#','')
    };
  },
  computed: {
    ...mapState(["accountInfo"])
  },
  methods: {
    handleCommand() {
      this.$router.push(ROUTER_PATH.LOGIN_OUT);
    }
  }
};
</script>

<style>
.el-menu--horizontal {
  border: none !important;
}
</style>
